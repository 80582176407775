import axios from 'axios'
import router from '@/router'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { showNotify } from 'vant';
// https://2t7k012012.oicp.vip/jngjcdglpt/
// create an axios instance
const service = axios.create({
    baseURL: 'https://vote.qiuhesys.com', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 15000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['X-Access-Token'] = getToken()
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.status != 'success') {
            // let token = getToken();
            // if (!token) {
            //     router.push({
            //         path: '/login'
            //     })
            //     return false
            // }
        }
        return res

    },
    error => {
        showNotify({ type: 'warning', message: error.response.data.err_msg });
        if (error.response.data.err_msg == '请登录后操作') {
            router.push({
                path: '/login'
            })
        }
        return Promise.reject(error)
    }
)

export default service