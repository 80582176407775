const TokenKey = 'jt_token'

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
}

//用户信息
export function getUserInfo() {
    return localStorage.getItem('userInfo')
}

//用户信息
export function setUserInfo(userInfo) {
    return localStorage.setItem('userInfo', userInfo)
}