import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";

const routes = [{
    path: "/",
    name: "index",
    component: () =>
        import("../views/index.vue"),
},{
    path: "/chaxun",
    name: "chaxun",
    component: () =>
        import("../views/chaxun.vue"),
},{
    path: "/wuliu",
    name: "wuliu",
    component: () =>
        import("../views/wuliu.vue"),
}];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

export default router;