import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'amfe-flexible'
import Vant from 'vant';
import 'vant/lib/index.css';
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css

const app = createApp(App);
app.use(Vant)
app.use(vue3videoPlay)
app.use(store).use(router).mount("#app");