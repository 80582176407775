import request from '@/utils/request'

export function queryCard(data) {
    return request({
        url: '/api/queryCard',
        method: 'get',
        params: data
    })
}

export function useCard(data) {
    return request({
        url: '/api/useCard',
        method: 'post',
        data
    })
}

export function queryWuliu(data) {
    return request({
        url: '/api/query',
        method: 'get',
        params: data
    })
}
export function delivery(data) {
    return request({
        url: '/api/delivery',
        method: 'post',
        data
    })
}
